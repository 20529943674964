@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --hougarden-primary-color: #20a4f8;
  --hougarden-bgc: #fff;
  --hougarden-font-family: 'CircularStd', 'PingFang-SC-Medium', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei';
  --hougarden-color: #242831;
  --container-pd: 10px;
}

@media (min-width: 768px) {
  :root {
    --container-pd: 18px;
  }
}

@media (min-width: 1024px) {
  :root {
    --container-pd: 26px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: var(--hougarden-bgc);
  font-family: var(--hougarden-font-family);
  text-rendering: optimizeLegibility;
  color: var(--hougarden-color);
  font-size: .7rem;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

p {
  margin: 0 0 .5rem;
}

.tcplayer {
  position: absolute !important;
  height: auto !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

mark {
  color: var(--hougarden-primary-color);
  background-color: transparent;
  padding-left: 2px;
  padding-right: 2px;
}

.tcplayer video {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.section-wrap {
  max-width: 100%;
  width: calc(1350px + var(--container-pd) * 2);
  padding-left: var(--container-pd);
  padding-right: var(--container-pd);
  box-sizing: border-box;
  margin: 0 auto;
}

@layer components {
  .main-wrap {
    @apply min-h-[calc(100vh-56px)] bg-cf5;
  }
}

@keyframes riseup {
  0% { top: 0.5rem; opacity: 0 }
  100% { top: 0; opacity: 1 }
} 

@keyframes audioPulse1 {
  0%,20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes audioPulse2 {
  0%,50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(0,0,0,.1);
  border-radius: 2000px;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
  border-radius: 2000px;
}

.custom-scroll-bar:hover::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.4);
}

.mapboxgl-marker:hover {
  z-index: 10!important;
}